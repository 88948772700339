/* eslint-disable @typescript-eslint/no-explicit-any */

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CSSProperties, useRef, useState } from 'react'

import Modal from '@/components/Modal'
import Slides from '@/components/Slides'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

type EntryProps = {
  title: string
  slug: string
  slides?: number
  topicTheme?: {
    background: string
    promoted: string
  }
} & (
  | {
      data?: undefined | null
      modal?: false | undefined
    }
  | {
      data: any
      modal?: true
    }
)

export default function PresentationEntry({ title, slug, topicTheme, modal, data }: EntryProps) {
  const router = useRouter()
  const fromElement = useRef<HTMLButtonElement | HTMLAnchorElement | null>(null)
  const [expanded, setExpanded] = useState(false)
  const [loadPresentation, setLoadPresentation] = useState(false)

  const toggle = (value: boolean) => () => {
    setExpanded(value)

    if (value && !loadPresentation) {
      setLoadPresentation(true)
    }
  }

  const styles: CSSProperties = {
    '--color-background': topicTheme?.background,
    '--color-promoted': topicTheme?.promoted,
  }

  const isModal = !!(modal && data)

  /**
   *
   * href={{
                  pathname: getUrl(entry?._type, entry?.slug),
                  query: { referer: router.asPath },
                }}
   */

  const Node = isModal ? 'button' : Link
  const props = isModal
    ? { onClick: toggle(true) }
    : { href: { pathname: `/presentasjon/${slug}`, query: { referer: router.asPath } } }

  return (
    <>
      {/* @ts-expect-error: It's a button or a link, so props will be passed correctly */}
      <Node {...props} {...bem('')} style={styles} ref={fromElement}>
        {/* Needs fix */}
        <Image
          src="/icons/presentasjon.png"
          alt=""
          {...bem('illustration')}
          width={200}
          height={200}
        />
        <span {...bem('tag')}>Presentasjon</span>
        <h3 {...bem('title')}>{title}</h3>
        {/* {slides && <span {...bem('summary')}>{slides} slides</span>} */}
      </Node>

      <Modal
        expanded={!!expanded}
        fromElement={fromElement?.current}
        onClose={toggle(false)}
        preventClickOutside
        slides
        autoWidth={undefined}
      >
        {data ? <Slides {...data} fluid /> : 'Laster...'}
      </Modal>
    </>
  )
}
