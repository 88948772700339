/* eslint-disable @typescript-eslint/no-explicit-any */

import { useRef, useState } from 'react'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import Quiz from '@/components/Quiz'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

interface Props {
  title: string
  questions?: number
  data: any
}

export default function QuizEntry({ title, questions, data }: Props) {
  const fromElement = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)

  const toggle = (value) => () => {
    setExpanded(value)
  }

  return (
    <div {...bem('')} ref={fromElement}>
      <picture {...bem('illustration')}>
        <source srcSet="/icons/quiz.webp" type="image/webp" />
        <source srcSet="/icons/quiz.png" type="image/png" />
        <img src="/icons/quiz.png" alt={title} width="300" />
      </picture>
      <div {...bem('content')}>
        <h3 {...bem('title')}>{title}</h3>
        <span {...bem('summary')}>{questions} spørsmål</span>

        <Button onClick={toggle(true)} primary {...bem('button')}>
          Ta quiz
        </Button>
      </div>

      <Modal
        expanded={!!expanded}
        fromElement={fromElement?.current}
        onClose={toggle(false)}
        preventClickOutside
        transparent
        autoWidth={undefined}
        article={undefined}
      >
        {data && <Quiz {...data} fluid />}
      </Modal>
    </div>
  )
}
