import type { ReactNode } from 'react'

import MainIllustration from '@/components/MainIllustration'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

interface Props {
  children: ReactNode
  hasSearch?: boolean
  title?: string
}

export default function FrontpageCover({ title, children, hasSearch }: Props) {
  return (
    <div {...bem('')}>
      <div {...bem('content')}>
        <h1 {...bem('title')}>
          {title || (
            <>Undervisnings&shy;opplegg for ungdom i kritisk mediebruk og kildebevissthet</>
          )}
        </h1>
        <div {...bem('children')}>{children}</div>
      </div>

      <div {...bem('illustration', { collapse: hasSearch })}>
        <div>
          <MainIllustration />
        </div>
      </div>
    </div>
  )
}
