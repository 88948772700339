/* eslint-disable indent */
import { useState } from 'react'

import BEMHelper from '@/helpers/bem'
import getTopic from '@/lib/topic'
import styles from './Styles.module.scss'

import Button from '@/components/Button'
import { Entry as TaskEntry } from '@/components/InteractiveTask'
import { Entry as LearningAidsEntry } from '@/components/LearningAidsEntry'
import { Entry as LessonEntry } from '@/components/LessonEntry'
import PresentationEntry from '@/components/PresentationEntry'
import QuizEntry from '@/components/QuizEntry'
import { TalkingEntry } from '@/components/TalkingCards'
import { Entry as VideoEntry } from '@/components/VideoEntries'
import useResize from '@/hooks/useResize'

const bem = BEMHelper(styles)

interface Props {
  title: string
  preamble: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[] // TODO: Remember to type this.. one day
}

export default function PromotedResources({ title, preamble, items }: Props) {
  const [count, setCount] = useState(1)
  const [showMore, setShowMore] = useState(false)
  const displayedItems = showMore ? items : [...items].slice(0, count)
  const remainingItems = items.length - displayedItems.length

  useResize(() => {
    const windowWidth = window.innerWidth

    if (windowWidth >= 1500) {
      setCount(3)
    } else if (windowWidth >= 760) {
      setCount(2)
    } else if (windowWidth < 760) {
      setCount(1)
    }
  }, [items])

  if (!items?.length) {
    return null
  }

  const getType = () => {
    if (items.length > 3) {
      return ['three', 'more']
    } else if (items.length === 3) {
      return 'three'
    } else if (items.length === 2) {
      return 'double'
    } else if (items.length === 1) {
      return 'single'
    }

    return null
  }

  const type = getType()
  const topic = getTopic(items.find((item) => !!item?.topicTheme)?.topicTheme)

  const styles =
    type === 'single' && topic
      ? {
          '--color-dark': topic.dark,
          '--color-promoted': topic.promoted,
          '--color-background': topic.background,
          '--color-light': topic.light,
        }
      : undefined

  return (
    <section {...bem('', type)} style={styles}>
      <div {...bem('content')}>
        <h3 {...bem('title')}>{title}</h3>
        <p {...bem('preamble')}>{preamble}</p>
      </div>

      <div {...bem('list')}>
        {displayedItems.map((item) => {
          switch (item?._type) {
            case 'lesson':
              const squared = items.length > 1 && items.some((item) => item._type !== 'lesson')

              return <LessonEntry key={item._id} {...item} square={squared} topicTheme={topic} />

            case 'video':
              return (
                <VideoEntry key={item._id} {...item} square={items.length > 1} topicTheme={topic} />
              )

            case 'presentation':
              return <PresentationEntry key={item._id} {...item} topicTheme={topic} />

            case 'talkingType':
              return <TalkingEntry key={item._id} {...item} topicTheme={topic} />

            case 'quiz':
              return <QuizEntry key={item._id} {...item} topicTheme={topic} />

            case 'resource':
              return <LearningAidsEntry key={item._id} {...item} topicTheme={topic} />

            case 'interactiveTask':
              return <TaskEntry key={item._id} {...item} topicTheme={topic} />

            case 'subTopic':
              return <LearningAidsEntry key={item._id} {...item} topicTheme={topic} />
          }
        })}
      </div>

      {items.length > 3 && !showMore && (
        <div {...bem('more')}>
          <Button onClick={() => setShowMore(true)} more primary>
            {remainingItems} flere
          </Button>
        </div>
      )}
    </section>
  )
}
