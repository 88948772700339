import BlockContent from '@sanity/block-content-to-react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import Image from '../Image'

import { dataset, projectId } from '../../api/sanity'
import BEMHelper from '../../helpers/bem'
import blockContentSerializers from '../../helpers/block-content-serializers'
import Button from '../Button'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

const KEY_LEFT = 37
const KEY_RIGHT = 39

export default function Slides({ slides = [], fluid }) {
  const router = useRouter()
  const [slide, setSlide] = useState(0)
  const next = useCallback(() => {
    setSlide((slide) => Math.min(slides?.length - 1, slide + 1))
  }, [slides?.length])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === KEY_LEFT) {
        back()
      } else if (event.keyCode === KEY_RIGHT) {
        next()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [slide, next])

  if (!slides) {
    return null
  }

  const back = () => {
    setSlide((slide) => Math.max(0, slide - 1))
  }

  return (
    <main {...bem('', { fluid })}>
      <nav {...bem('controls')}>
        <Button
          icon="chevron"
          iconProps={{ direction: 'left' }}
          circle
          disabled={slide === 0}
          onClick={back}
          {...bem('control')}
        />
        <Button
          icon="chevron"
          circle
          onClick={next}
          disabled={slide === slides?.length - 1}
          {...bem('control')}
        />
        {!fluid && (
          <Button
            {...bem('control', 'close')}
            circle
            href={router?.query?.referer || '/'}
            label="Lukk"
            icon="close"
          />
        )}
      </nav>

      <div {...bem('wrapper')} style={{ transform: `translateX(-${slide * 100}%)` }}>
        {slides.map(({ _key, _type, title, image, text, imageSize }) => {
          switch (_type) {
            case 'fullscreen':
              return (
                <article
                  key={_key}
                  {...bem('slide', _type)}
                  style={
                    image?.palette?.dominant?.background
                      ? { '--background': image?.palette?.dominant?.background }
                      : {}
                  }
                >
                  <div
                    {...bem('image-wrapper', imageSize)}
                    style={{
                      '--aspect': `${image?.dimensions?.width}/${image?.dimensions?.height}`,
                    }}
                  >
                    <Image {...image} alt={image?.alt || ''} {...bem('image', 'fullscreen')} />
                  </div>
                </article>
              )

            case 'split':
              return (
                <article key={_key} {...bem('slide', [_type, `image-${imageSize}`])}>
                  <Image {...image} alt={image?.alt || ''} {...bem('image', 'split')} />

                  <div {...bem('content')}>
                    {title && <h1 {...bem('title')}>{title}</h1>}
                    <BlockContent
                      renderContainerOnSingleChild
                      className={bem('text').className}
                      blocks={text}
                      serializers={blockContentSerializers}
                      dataset={dataset}
                      projectId={projectId}
                    />
                  </div>
                </article>
              )

            case 'headline':
              return (
                <article key={_key} {...bem('slide', _type)}>
                  <h1 {...bem('title', 'xl')}>{title}</h1>
                </article>
              )

            case 'text-content':
              return (
                <article key={_key} {...bem('slide', _type)}>
                  <div {...bem('content')}>
                    {title && <h1 {...bem('title')}>{title}</h1>}
                    <BlockContent
                      renderContainerOnSingleChild
                      className={bem('text').className}
                      blocks={text}
                      serializers={blockContentSerializers}
                      dataset={dataset}
                      projectId={projectId}
                    />
                  </div>
                </article>
              )
          }

          return null
        })}
      </div>
    </main>
  )
}
