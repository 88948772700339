/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'

import { getImageUrl } from '@/api/sanity'

import getUrl from '@/helpers/links'

import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

type ImageType = SanityImageSource & { hasAlpha?: boolean; alt?: string }

interface Props {
  title: string
  preamble: string
  image?: ImageType
  external?: string
  reference?: {
    _type: string
    title?: string
    topicTheme?: {
      background: string
      promoted: string
    }
    image?: ImageType
    slug: string
  }
}

export default function Promotion({ title, preamble, external, reference, ...props }: Props) {
  const image = props.image || reference?.image
  const imageURL = image && getImageUrl(image).width(800).url()
  const href = (reference && getUrl(reference._type, reference.slug)) || external || '/'

  if (!href) {
    return null
  }

  const styles = reference?.topicTheme
    ? {
        '--color-background': reference?.topicTheme?.background,
        '--color-promoted': reference?.topicTheme?.promoted,
      }
    : undefined

  return (
    <Link {...bem('', { image: !image?.hasAlpha })} href={href} style={styles}>
      <div {...bem('image-wrapper')}>
        {imageURL && <img {...bem('image')} src={imageURL} alt={image?.alt || ''} />}
      </div>

      <div {...bem('content')}>
        <h2 {...bem('title')}>{title}</h2>
        <p {...bem('preamble')}>{preamble}</p>
      </div>
    </Link>
  )
}
